@import 'react-toastify/dist/ReactToastify.css';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #979797;
  outline: 1px solid #979797;
}

.center-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.read-more-button {
  color: #3575f0;
  cursor: pointer;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
